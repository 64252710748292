import { render, staticRenderFns } from "./tPortfolioProjectList.vue?vue&type=template&id=0ae2fd1d&"
import script from "./tPortfolioProjectList.vue?vue&type=script&lang=js&"
export * from "./tPortfolioProjectList.vue?vue&type=script&lang=js&"
import style0 from "./tPortfolioProjectList.vue?vue&type=style&index=0&id=0ae2fd1d&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Java\\healthy\\healthy-frame-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0ae2fd1d')) {
      api.createRecord('0ae2fd1d', component.options)
    } else {
      api.reload('0ae2fd1d', component.options)
    }
    module.hot.accept("./tPortfolioProjectList.vue?vue&type=template&id=0ae2fd1d&", function () {
      api.rerender('0ae2fd1d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/healthy/tPortfolioProject/tPortfolioProjectList.vue"
export default component.exports